import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function EasyWords() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        Easy Words is a Language App to learn and remember German words. You can
        create your own word lists or use the pre-made ones. You will get daily
        reminders and depending on your list type you will automatically get new
        words every day.
      </Typography>
      <br />
      <Typography variant="body1">
        It currently has 5 pre-made levels such as A1,A2,B1,B2 and C1. You can
        still make additions to these lists.
      </Typography>
      <br />
      <Typography variant="body1">
        Day by day, getting feedback from your answers, Easy Words pushes you to
        learn better and shows your strong words less and weak words more.
        Everyday you will get your new words but you will have to redo the ones
        from other days.
      </Typography>
      <br />
      <Typography variant="body1">
        <Link color="secondary" href="#">Soon Available on Google Play</Link>
      </Typography>
    </Grid>
  );
}

export default EasyWords;
