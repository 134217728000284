import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import EmojiEventsRoundedIcon from "@material-ui/icons/EmojiEventsRounded";
import BuildIcon from '@material-ui/icons/Build';
import HomeIcon from "@material-ui/icons/Home";
import BusinessIcon from '@material-ui/icons/Business';
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  URL_ABOUTME,
  URL_EXPERIENCES,
  URL_PROJECTS,
  URL_SKILLS,
} from "../../Constants/routes.js";
import useStyles from "./styles.js";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
function Sidebar(props) {
  const classes = useStyles();
  function goTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  function getIconList() {
    return [
      { icon: <GitHubIcon />, url: "https://github.com/Batuu13" },
      { icon: <LinkedInIcon />, url: "https://www.linkedin.com/in/batuhanyaman13/" },
      { icon: <TwitterIcon />, url: "https://www.twitter.com/drummerbatu" },
    ];
  }
  function getIconBar() {
    return (
      <Grid container justify="center" className={classes.iconBar}>
        {getIconList().map((icon) => (
          <Grid key={icon.url}>
            <a target="_blank" href={icon.url}>
              <IconButton>{icon.icon}</IconButton>
            </a>
          </Grid>
        ))}
      </Grid>
    );
  }
  function MenuItem(item) {
    const location = useLocation();
    const classes = useStyles();
    return (
      <ListItem
        component={NavLink}
        selected={location.pathname === `/${item.url}`}
        classes={{ selected: classes.selected }}
        to={`/${item.url}`}
        key={item.url}
        className={classes.menuItem}
        button
        onClick={() => {
          props.closeDrawer();
          goTop();
        }}
      >
        <MuiListItemIcon>
          <item.icon
            className={
              location.pathname === `/${item.url}`
                ? classes.iconSelected
                : classes.icon
            }
          />
        </MuiListItemIcon>
        <ListItemText className={classes.title} primary={item.title} />
      </ListItem>
    );
  }
  function getMenuList() {
    return [
      {
        title: "Home",
        url: URL_ABOUTME,
        icon: HomeIcon,
      },
      {
        title: "Projects",
        url: URL_PROJECTS,
        icon: BuildIcon,
      },
      {
        title: "Skills",
        url: URL_SKILLS,
        icon: EmojiEventsRoundedIcon,
      },
      {
        title: "Experiences",
        url: URL_EXPERIENCES,
        icon: BusinessIcon,
      },
    ];
  }
  return (
    <Grid container spacing={0} direction="column" className={classes.sidebar}>
      <Grid
        className={classes.avatarWrapper}
        alignContent="center"
        direction="column"
        justify="center"
        container
      >
        <img
          className={classes.avatar}
          alt="Batuhan Yaman"
          src="/images/me.jpg"
        />
        <Typography color={"textPrimary"} className={classes.webTitle}>
          Batuhan Yaman
        </Typography>
        <Typography color={"textSecondary"} className={classes.webTitle}>
          Computer Engineer
        </Typography>
      </Grid>

      <Grid item>{getIconBar()}</Grid>
      <Divider color={"secondary"} />
      <Grid item>
        <List className={classes.menu} component="nav">
          {getMenuList().map((item) => MenuItem(item))}
        </List>
      </Grid>
    </Grid>
  );
}

export default Sidebar;
