import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function ColorTransfer() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        In this project, I implemented an algorithm using Ruderman method to
        transfer colors in given source image to target image. This project has
        two parts. I used this project for my Image Processing Class. I do not
        own any rights on the pictures. If you own them and want credit or want
        them taken down please feel free to contact me.
      </Typography>
      <Typography variant="body1">
        <Link color="secondary"
          href="https://github.com/Batuu13/Color-Transfer-Between-Images"
          target="_blank"
        >
          You can reach the code and report from my GitHub.
        </Link>
      </Typography>
    </Grid>
  );
}

export default ColorTransfer;
