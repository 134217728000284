import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function HashCode2007() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        Hash Code is a team-based programming competition organized by Google
        Paris. Every year they announce a real-life problem as the challenge.
        You can reach the website from here
        <Link color="secondary" href=">https://hashcode.withgoogle.com" target="_blank">
          https://hashcode.withgoogle.com
        </Link>
      </Typography>
      <br />
      <Typography variant="body1">
        This year my team and I participated. Even though we had some problems
        on the Online Qualification Round which caused us not to go to Paris, we
        implemented our algorithm on the first day of the Extended round and got
        satisfactory results
      </Typography>
      <Typography variant="body1">
        <b>2372746 Points. 192nd At Global.</b>
      </Typography>
      <Typography variant="body1">
        <b>You can reach our algorithm and our code in java via GitHub </b>
        <Link color="secondary"
          href="https://github.com/Batuu13/Google-Hash-Code-2017"
          target="_blank"
        >
          here
        </Link>
      </Typography>
    </Grid>
  );
}

export default HashCode2007;
