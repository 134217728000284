import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function SpaceRush() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        Space Rush is a endless space game. The Goal is having the highest score
        and lasting the longest. You can join the highscore table if you connect
        via Facebook. Made with unity and available on Google Play.
      </Typography>

      <Typography variant="body1">
        <Link color="secondary"
          href="https://play.google.com/store/apps/details?id=com.batuhanyaman.spacerush"
          target="_blank"
        >
          https://play.google.com/store/apps/details?id=com.batuhanyaman.spacerush
        </Link>
      </Typography>
    </Grid>
  );
}

export default SpaceRush;
