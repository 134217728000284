import { Chip } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

export default function TagList(props) {
  const { tags } = props;

  const classes = useStyles();
  return (
    <div className={classes.tagWrapper}>
      {tags.map((tag) => (
        <li key={tag}>
          <Chip color="primary" label={tag} className={classes.tag} />
        </li>
      ))}
    </div>
  );
}
