import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
  },
  image: {
    height: 200,
    objectFit: "cover",
  },
  tagList: {
    display: "flex",
    flex: 1,
    alignItems: "flex-end",
    padding: 10,
    width : '100%'
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    height: "100%",
  },
  date : {
    fontSize : 20,
    marginBottom : 20,
  }
}));
export default useStyles;
