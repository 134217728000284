import colors from "../../Constants/colors";

const { createMuiTheme } = require("@material-ui/core");

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: colors.darkest,
    },
    secondary: {
      main: colors.accent,
    },
    action: {
      selected: colors.accent,
    },
    tonalOffset: 0.6,
  },
  typography: {
    fontFamily: "Nunito",
    fontSize: 16,
    h1: {
      fontWeight: "bold",
      fontSize: 36,
      lineHeight: 1.5,
    },
    h2: {
      fontWeight: "bold",
      fontSize: 24,
      lineHeight: 1,
      marginBottom: 10,
      marginTop: 10,
    },
    h3: {
      fontSize: 24,
      lineHeight: 1,
      marginBottom: 10,
      marginTop: 10,
    },
    body1: {
      lineHeight: 2,
    },
    body2: {
      fontSize: 18,
      color: "rgba(0,0,0,0.5)",
    },
  },
});
export default theme;
