import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import ProjectImageList from "../../Components/ProjectImageList/ProjectImageList";
import { useLocation } from "react-router-dom";
import { PROJECTS } from "./data";
import TagList from "../../Components/TagList/TagList";
import { getDateString } from "../../Utils/DateManager";
function getProjectUrl(path) {
  return path.substring(path.substring(1, path.length).search("/") + 2);
}
function ProjectViewWrapper(props) {
  const classes = useStyles();
  const location = useLocation();

  const project = PROJECTS.filter(
    (p) => p.url === getProjectUrl(location.pathname)
  )[0];

  return (
    <Grid wrap={"nowrap"} container spacing={2} direction="column">
      <Paper className={classes.paperHeader}>
        <Grid container wrap={"wrap"} direction="row">
          <Grid item lg={7} md={12}>
            <Grid container wrap={"nowrap"} direction="column">
              <Grid item xs={12}>
                <Typography variant="h1" color={"textPrimary"}>
                  {project.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3" color={"textSecondary"}>
                  {getDateString(project.date)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} md={12}>
            <TagList tags={project.tags} />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        {project.images && project.images.length > 0 && (
          <ProjectImageList
            isCarousel={project.isCarousel}
            images={project.images}
          />
        )}
        {props.children}
      </Paper>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  paperHeader: {
    padding: 20,
    paddingLeft: 40,
    marginBottom: 10,
  },
  paper: {
    padding: 40,
  },
}));
export default ProjectViewWrapper;
