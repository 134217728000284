import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles.js";

function About() {
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column" className={classes.sidebar}>
      <Typography color={"textPrimary"} variant="h1">
        About Me
      </Typography>
      <Typography color={"textSecondary"} variant="body1">
        So, I'm Batuhan. I was born in Izmir, Turkey in 1995. I have been always fond of
        computers and programming since my childhood. I guess what lead to my
        this area is my father's interest and knowledge about it. I wrote my
        first program in Visual Basic 6.0 when I was in primary school. I love
        to be able to overcome every problem in different subjects. I'm not
        sure, maybe because it was a necessity for me so far. After all, I had
        to deal with both coding, design, marketing for my projects. Since I
        have been doing this for a while, I had experiences in many fields. And
        I am looking forward for new technologies and ways to improve myself.
      </Typography>
      <br/>
      <Typography color={"textSecondary"} variant="body1">
        As for hobbies, I like creating web sites and mobile apps for new ideas.
        But besides computers, I play drums and did some gigs in the past. And
        also I did produce music as a DJ in high school. I love doing sports.
        And of course, I like playing video games. I am a total team player, I
        like competition.
      </Typography>
      <Typography color={"textPrimary"} variant="h2">
        Education:
      </Typography>
      <ul>
        <li>
          <Typography color={"textSecondary"} variant="body1">
            Hacettepe University, Turkey - Computer Science (2013-2017)
          </Typography>
        </li>
        <li>
          <Typography color={"textSecondary"} variant="body1">
            Heinrich Heine University, Germany - Computer Science (1 term
            Erasmus, 2016)
          </Typography>
        </li>
        <li>
          <Typography color={"textSecondary"} variant="body1">
            Bornova Anatolian Highschool (2009-2013)
          </Typography>
        </li>
      </ul>
    </Grid>
  );
}

export default About;
