import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function PredictingReviewsAttitude() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        Predicting reviews attitude using Bag of Words and Naive Bayes with
        Python In this project, I implemented an algorithm using python to
        predict if the given review is positive or negative. I used "Bag of
        words" method to get the frequency of the words and Naive Bayes to
        determine the class. I used this project for my Machine Learning Class.
      </Typography>
      <Typography variant="body1">
        <Link color="secondary"
          href="https://github.com/Batuu13/Predicting-Reviews-Attitude"
          target="_blank"
        >
          You can reach the code and report from my GitHub.
        </Link>
      </Typography>
    </Grid>
  );
}

export default PredictingReviewsAttitude;
