import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import useStyles from "./styles";
import { getDateString } from "../../Utils/DateManager";
import Link from "@material-ui/core/Link";

export default function ExperienceCard(props) {
  const classes = useStyles();
  const { experience } = props;
  return (
    <Card className={classes.root}>
      <CardContent className={classes.card}>
        <Grid container spacing={4} direction="row">
          <Grid item container sm={3} alignContent="center" justify="center">
            <img
              className={classes.logo}
              src={experience.image}
              alt="company_logo"
            />
          </Grid>
          <Grid
            item
            alignItems="flex-start"
            container
            sm={9}
            direction="column"
          >
            <Grid container direction="column" spacing={0}>
              <Grid item container spacing={2}>
                <Grid item>
                  <Link color="secondary" href={experience.url} target="_blank">
                    <Typography variant="h2" color="secondary" component="h2">
                      {experience.company}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Typography variant="h3" color="textSecondary" component="h3">
                    {experience.title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  component="span"
                >
                  {`${getDateString(experience.startTime)} `}
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  component="span"
                >
                  -
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  component="span"
                >
                  {experience.endTime === null
                    ? " Current"
                    : ` ${getDateString(experience.endTime)} `}
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="body1" color="textPrimary" component="span">
              {experience.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
