import { Grid, GridList, GridListTile, Typography } from "@material-ui/core";
import ProjectCard from "../../Components/ProjectCard/ProjectCard.js";
import { PROJECTS } from "./data.js";
import useStyles from "./styles.js";
function Projects() {
  const classes = useStyles();

  function sortProjects(projectA, projectB) {
    return (
      new Date(projectB.date).getTime() - new Date(projectA.date).getTime()
    );
  }
  return (
    <Grid container spacing={2} direction="column">
      <Typography color={"textPrimary"} variant="h1">
        Projects
      </Typography>
      <Grid item>
        <Grid container spacing={5} direction="row" className={classes.wrapper}>
          {PROJECTS.sort(sortProjects).map((project) => (
            <Grid
              key={project.url}
              item
              xl={4}
              lg={6}
              sm={12}
              className={classes.gridTile}
            >
              <ProjectCard project={project} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Projects;
