import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function EstimatingPreferencesYelp() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        I did this project with a team for my Machine Learning Project. Our
        project aims to estimate what customer values on restaurants by region.
        We intend to improve the business for restaurants that currently open or
        will we available in future.
      </Typography>
      <br />
      <Typography variant="body1">
        Do you have friends that don’t eat any restaurant unless it is their
        favorite? Well, If you ever wondered, your friend probably has an
        answer, go and ask them. What about that little local restaurant which
        is always so crowded while big restaurants around it have no customers?
        Now, that’s a curiosity which is hard to satisfy. Crowds cannot answer
        your questions like your friend does, you cannot ask them a random
        question (unless you do surveys, but that’s another topic), but you can
        make inferences from their actions. So this is what we are planning to
        do, looking for an answer what makes that restaurant good, what does a
        particular neighborhood expects from a restaurant, we are going to try
        to find a connection between restaurants and peoples that lives near.
      </Typography>

      <Typography variant="body1">
        <b>Data Set: </b>
        <Link color="secondary" href="https://www.yelp.com/dataset_challenge" target="_blank">
          Yelp’s Data
        </Link>
      </Typography>
      <Typography variant="body1">
        <b>Our Github is available </b>
        <Link color="secondary" href="https://github.com/Batuu13/Machine-Likes-It" target="_blank">
          here
        </Link>
      </Typography>
      <Typography variant="body1">
        <b>
          For the explanation of our algorithm and our results please read our
          report{" "}
        </b>
        <Link color="secondary"
          href="http://www.batuhanyaman.com/wp-content/uploads/estimating-preferences-city.pdf"
          target="_blank"
        >
          here
        </Link>
      </Typography>
      <Typography variant="body1">
        We are writing a blog on Medium about it almost every week. You can
        check it on here
      </Typography>
      <Typography variant="body1">
        <Link color="secondary" href=" https://medium.com/@machine.likes.it" target="_blank">
          Medium - Machine Likes It
        </Link>
      </Typography>
      <Typography variant="body1">
        <ul>
          <li>
            <Link color="secondary"
              href="https://medium.com/bbm406f16/what-do-people-want-from-your-restaurant-92d9155319ac"
              target="_blank"
            >
              Week 1 — Estimating Preferences By Region Using Yelp Data
            </Link>
          </li>
          <li>
            <Link color="secondary"
              href="https://medium.com/bbm406f16/week-3-estimating-preferences-by-region-using-yelp-data-30e3d2b1b69e"
              target="_blank"
            >
              Week 3 — Parsing and Storing The Data
            </Link>
          </li>
          <li>
            <Link color="secondary"
              href="https://medium.com/bbm406f16/week-4-estimating-preferences-by-region-using-yelp-data-e0a2ada4220a"
              target="_blank"
            >
              Week 4 — Results and Algorithms
            </Link>
          </li>
          <li>
            <Link color="secondary"
              href="https://medium.com/bbm406f16/week-5-estimating-preferences-by-region-using-yelp-data-ad364d09dd90"
              target="_blank"
            >
              Week 5 — Better Results and playing with parameters
            </Link>
          </li>
          <li>
            <Link color="secondary"
              href="https://medium.com/bbm406f16/final-week-estimating-preferences-by-region-using-yelp-data-f4fe7103e7e7"
              target="_blank"
            >
              Week 6 — Final Week
            </Link>
          </li>
        </ul>
      </Typography>
    </Grid>
  );
}

export default EstimatingPreferencesYelp;
