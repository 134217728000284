import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  sidebar: {
    padding: 20,
  },
  avatarWrapper: {
    padding: 50,
  },
  avatar: {
    height: "100%",
    maxHeight: 200,
    maxWidth: 200,
    width: "100%",
    borderRadius: 500,
  },
  menu: {
    width: "100%",
  },
  menuItem: {
    width: "100%",
    fontSize: 20,
    padding: 20,
  },
  webTitle: {
    textAlign: "center",
  },
}));
export default useStyles;
