import { Container, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./styles";
import ItemsCarousel from "react-items-carousel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import withWidth from "@material-ui/core/withWidth";
function ProjectImageList(props) {
  const { images, isCarousel, width } = props;
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const getImageCountByWidth = () => {
    switch (width) {
      case 'xs':
        return 1;
      case 'sm':
        return 2;
      case 'md':
        return 2;
      case 'lg':
        return 3;
      case 'xl':
        return 4;
    }
  };
  if (isCarousel) {
    const items = images.map((i, imageIndex) => (
      <img key={`image-${imageIndex}`} src={i.url} className={classes.image} />
    ));
    return (
      <Container className={classes.imageListWrapper}>
        <ItemsCarousel
          // Placeholder configurations
          enablePlaceholder
          numberOfPlaceholderItems={5}
          minimumPlaceholderTime={1000}
          placeholderItem={
            <div style={{ height: 200, background: "#900" }}>Placeholder</div>
          }
          // Carousel configurations
          numberOfCards={getImageCountByWidth()}
          gutter={12}
          showSlither={true}
          firstAndLastGutter={true}
          freeScrolling={false}
          // Active item configurations
          requestToChangeActive={setIndex}
          activeItemIndex={index}
          activePosition={"center"}
          chevronWidth={48}
          rightChevron={<ChevronRightIcon className={classes.chevron} />}
          leftChevron={<ChevronLeftIcon className={classes.chevron} />}
          outsideChevron={true}
        >
          {items}
        </ItemsCarousel>
      </Container>
    );
  }
  return (
    <Grid container direction="row" spacing={5} justify="flex-start">
      {images.map((image) => (
        <Grid key={`grid-${image.url}`} item xs={12} sm={6} lg={4} xl={3}>
          <img height={"auto"} width="100%" src={image.url} />
          <Typography variant="h2" align="center">
            {image.title}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}
export default withWidth()(ProjectImageList);
