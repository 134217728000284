import {
  CssBaseline,
  Drawer,
  Fab,
  Grid,
  Hidden,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import colors from "./Constants/colors";
import {
  URL_ABOUTME,
  URL_EXPERIENCES,
  URL_PROJECTS,
  URL_SKILLS,
} from "./Constants/routes";
import Sidebar from "./Layout/Sidebar/Sidebar";
import About from "./Views/About/About";
import Experiences from "./Views/Experiences/Experiences";
import { PROJECTS } from "./Views/Projects/data";
import Projects from "./Views/Projects/Projects";
import ProjectViewWrapper from "./Views/Projects/ProjectViewWrapper";
import Skills from "./Views/Skills/Skills";

const drawerWidth = 320;
function getProjectRoutes() {
  return PROJECTS.map((project) => {
    return {
      path: `projects/${project.url}`,
      view: <ProjectViewWrapper>{project.component}</ProjectViewWrapper>,
    };
  });
}

function getRoutes() {
  return [
    {
      path: URL_ABOUTME,
      view: <About />,
    },
    {
      path: URL_PROJECTS,
      view: <Projects />,
    },
    {
      path: URL_SKILLS,
      view: <Skills />,
    },
    {
      path: URL_EXPERIENCES,
      view: <Experiences />,
    },
    ...getProjectRoutes(),
  ];
}

function App() {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <Grid container wrap="nowrap">
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden mdUp implementation="css">
              <Fab
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </Fab>
              <Drawer
                variant="temporary"
                anchor={"left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.sidebarSmall,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <Sidebar closeDrawer={() => setMobileOpen(false)} />
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.sidebar,
                }}
                variant="permanent"
                open
              >
                <Sidebar closeDrawer={() => setMobileOpen(false)} />
              </Drawer>
            </Hidden>
          </nav>
          <TransitionGroup className={classes.page}>
            <CSSTransition key={"location"} classNames="fade" timeout={300}>
              <div className={classes.page}>
                <Switch>
                  {getRoutes().map((route) => (
                    <Route key={route.path} exact path={`/${route.path}`}>
                      {route.view}
                    </Route>
                  ))}
                  <Route exact path="/">
                    <Redirect to={URL_ABOUTME} />
                  </Route>
                </Switch>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Grid>
      </div>
    </Router>
  );
}
const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: colors.darkest,
    width: drawerWidth,
  },
  sidebarSmall: {
    backgroundColor: colors.darkest,
    width: 240,
  },
  page: {
    display: "flex",
    flex: 1,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    width: "100%",
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 40,
    },
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
  },
  drawer: {
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    background: theme.palette.common.white,
    position: "fixed",
    margin: 10,
    marginTop: 10,
    zIndex: 30,
  },
  cssBaseline: {
    margin: "auto",
  },
}));
export default App;
