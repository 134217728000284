import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import useStyles from "./styles";

export default function SkillCard(props) {
  const classes = useStyles();
  const { skill } = props;
  return (
    <Card className={classes.root}>
      <CardContent className={classes.card}>
        <skill.icon className={classes.icon} />
        <Typography variant="h2" color="textPrimary" component="h2">
          {skill.title}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          component="span"
        >
          {skill.text}
        </Typography>
      </CardContent>
    </Card>
  );
}
