import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  tag: {
    margin: theme.spacing(0.5),
  },
  tagWrapper: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    margin : 10,
  },
}));
export default useStyles;
