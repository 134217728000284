import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  imageListWrapper: {
    marginBottom: 50,
  },
  image: {
    maxHeight: 500,
    maxWidth: 500,
  },
  chevron: {
    fontSize: 36,
  },
}));
export default useStyles;
