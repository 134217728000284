import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import TagList from "../TagList/TagList";
import useStyles from "./styles";
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function ProjectCard(props) {
  function limitInfo(text) {
    const LIMIT = 140;
    if (text.length > LIMIT) {
      return text.substring(0, LIMIT) + "...";
    } else {
      return text;
    }
  }

  const project = props.project;
  const projectDate = new Date(project.date);
  const classes = useStyles();
  let history = useHistory();
  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.card}
        onClick={() => history.push(`/projects/${project.url}`)}
      >
        <CardMedia
          component="img"
          alt="Project Thumbnail"
          className={classes.image}
          image={project.thumbnail}
        />
        <CardContent>
          <Typography variant="h2" color="textPrimary" component="h2">
            {project.title}
          </Typography>
          <Typography variant="h2" className={classes.date} color={"primary"}>
            {project.date &&
              `${MONTHS[projectDate.getMonth()]} ${projectDate.getFullYear()}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {limitInfo(project.info)}
          </Typography>
        </CardContent>
        <div className={classes.tagList}>
          <TagList tags={project.tags} />
        </div>
      </CardActionArea>
    </Card>
  );
}
