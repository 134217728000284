import { makeStyles } from "@material-ui/core/styles";
import colors from "../../Constants/colors";
const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: "100%",
  },
  avatarWrapper: {
    paddingTop: 50,
  },
  avatar: {
    height: "100%",
    maxHeight: 200,
    maxWidth: 200,
    width: "100%",
    borderRadius: 200,
  },
  menu: {
    paddingTop: 0,
  },
  menuItem: {
    width: "100%",
    fontSize: 20,
    padding: 20,
    color: theme.palette.primary.light,
    "&:hover, &:hover $icon, &:hover $title": {
      color: theme.palette.common.white,
    },
    "&$selected": {
      color: theme.palette.secondary.dark,
    },
  },
  webTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 24,
  },
  title: {
    fontWeight: "bold",
    letterSpacing: 2,
  },
  icon: {
    color: theme.palette.primary.light,
  },
  iconSelected: {
    color: theme.palette.secondary.dark,
  },
  selected: {
    "&:hover, &:hover $icon,&:hover $iconSelected, &:hover $title": {
      color: theme.palette.common.white,
    },
  },
  iconBar: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
export default useStyles;
