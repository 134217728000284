import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 20,
  },
  gridTile : {
    display : 'flex',
    margin : 0,
  }
}));
export default useStyles;
