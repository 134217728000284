import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  icon: {
    fontSize: 96,
    color: theme.palette.secondary.main,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  },
}));
export default useStyles;
