import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function SiteCrawler() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        A simple web crawler that only scans the given domain. It scans all of
        the links both internal and external and saves them and also stores all
        the assets for each page. This small coding project made for job
        inteview.
      </Typography>

      <Typography variant="body1">
        <Link color="secondary" href="https://github.com/Batuu13/Site-Crawler" target="_blank">
          Github
        </Link>
      </Typography>
    </Grid>
  );
}

export default SiteCrawler;
