import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles.js";
import { SKILLS } from "./data.js";
import SkillCard from "../../Components/SkillCard/SkillCard.js";
function Skills() {
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column">
      <Typography color={"textPrimary"} variant="h1">
        Skills
      </Typography>
      <Typography variant="body1" color="textPrimary" component="span">
        Programming Languages : Javascript, Typescript, Java, C#, C, PHP, Python, Visual
        Basic.
      </Typography>
      <Typography variant="body1" color="textSecondary" component="span">
        I haven't used your preferred language yet? No problem! I can learn it!
        I don't discriminate any of them :)
      </Typography>
      <Grid item>
        <Grid container spacing={5} direction="row" className={classes.wrapper}>
          {SKILLS.map((skill) => (
            <Grid
              key={skill.title}
              item
              xl={4}
              lg={6}
              sm={12}
              className={classes.gridTile}
            >
              <SkillCard skill={skill} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Skills;
