import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function DiscordSoundboard() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        Discord Soundboard is an app for Discord. It has over 300 sounds that
        you can play on your channel on real-time by using our mobile or web
        client.
      </Typography>
      <br />
      <Typography variant="body1">
        You can login with your discord account and whenever you want to play a
        sound, it will find your channel automatically if the bot is already
        added to your server. You can also have a favorites list and use the
        whole app offline as well.
      </Typography>
      <br />
      <Typography variant="body1">
        Backend is written with NodeJS on simple Express server and Nginx.
        Frontend is implemented with ReactJS for the web and React Native for
        the android. We currently only support Android as we don't hold a Apple
        Store Developer Account. So far, it has 26K+ downloads and 3.6K+ active
        users.
      </Typography>
      <br />
      <Typography variant="body1">
        <Link
          color="secondary"
          href="https://play.google.com/store/apps/details?id=com.batuhanyaman.mobilesoundboard"
        >
          Available on Google Play
        </Link>
      </Typography>
      <Typography variant="body1">
        <Link color="secondary" href="https://www.discordsoundboard.com">
          www.discordsoundboard.com
        </Link>
      </Typography>
    </Grid>
  );
}

export default DiscordSoundboard;
