import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function BouncyEggs() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        This is a game for Android phones made with Unity 3D. You have a pan and
        the eggs keep coming at you! You have to bounce them before breaking
        them. How long will you survive? It has features like unlockables,
        leaderboard, news. Available on Google Play.
      </Typography>
      <Typography variant="body1">
        <Link color="secondary"
          href="https://play.google.com/store/apps/details?id=com.batuhanyaman.bouncyeggs"
          target="_blank"
        >
          Get it on Google Play
        </Link>
      </Typography>
    </Grid>
  );
}

export default BouncyEggs;
