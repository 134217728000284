import { Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
function Kelimator() {
  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        Kelimatör is a word guessing game. There are themes and levels in these
        themes for users to discover. Size of the board starts with 2x2 and it
        increases as the levels go as well as the difficulty.
      </Typography>
      <br/>
      <Typography variant="body1">
        You collect coins and power after correctly guessing words. Then, you
        can use these powers to get clues about the words and compete with your
        coins on leaderboard. There is also a wheel to randomly collect daily
        rewards.
      </Typography>
      <br/>
      <Typography variant="body1">
        Made with unity using Javascript and C#. Downloaded over 20K. Available
        on Google Play.
      </Typography>
      <br/>
      <Typography variant="body1">
        <Link color="secondary"
          href="https://play.google.com/store/apps/details?id=com.batuhanyaman.kelimebulmaca"
          target="_blank"
        >
          Get it on Google Play
        </Link>
      </Typography>
    </Grid>
  );
}

export default Kelimator;
