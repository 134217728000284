import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperHeader: {
    padding: 20,
    paddingLeft: 40,
    marginBottom: 10,
  },
  paper: {
    padding: 40,
  },
}));
export default useStyles;
