import { Grid, Typography } from "@material-ui/core";
import ExperienceCard from "../../Components/ExperienceCard/ExperienceCard.js";
import { EXPERIENCES } from "./data.js";
import useStyles from "./styles.js";

function Experiences() {
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column">
    <Typography color={"textPrimary"} variant="h1">
      Experiences
    </Typography>
    <Typography variant="body1" color="textSecondary" component="span">
      The things I did professionally.
    </Typography>
    <Grid item>
      <Grid container spacing={5} direction="row" className={classes.wrapper}>
        {EXPERIENCES.map((experience) => (
          <Grid
            key={experience.company}
            item
            xl={12}
            lg={12}
            sm={12}
            className={classes.gridTile}
          >
            <ExperienceCard experience={experience} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
  );
}

export default Experiences;
