import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles.js";
import Link from "@material-ui/core/Link";
function AutomaticFaceSwap(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction="column">
      <Typography variant="body1">
        This project aims to swap faces on given two images. It automatically
        detects both faces and scales them But poor lighted or rotated faces may
        not be detected or result in an unsatisfactory mixture.Best results or
        obtained when both images have similar sizes.
      </Typography>
      <Typography variant="body1">
        You can reach our GitHub Rep&nbsp;
        <Link
          color="secondary"
          href="https://github.com/Batuu13/Automatic-FaceSwap"
          target="_blank"
        >
          here
        </Link>
      </Typography>
      <Typography variant="body1">
        You can read our research paper to see our results and have a deeper
        understanding of our algorithm&nbsp;
        <Link
          color="secondary"
          rel="noopener"
          href="https://github.com/Batuu13/Automatic-FaceSwap/blob/master/report.pdf"
          target="_blank"
        >
          here
        </Link>
      </Typography>
    </Grid>
  );
}

export default AutomaticFaceSwap;
