export const EXPERIENCES = [
  {
    company: "Metro Markets",
    title: "Software Engineer",
    location: "Düsseldorf, Germany",
    startTime: "11.01.2019",
    endTime: null,
    url: "https://www.metro-markets.de/",
    image: "/images/metromarkets_thumbnail.svg",
    description:
      "I have worked at Metro-Markets which is a start-up from Metro.de that aims to be a marketplace for the HoReCa industry. Worked with Angular 8, PHP with Symfony, Google Cloud as a Full-Stack Engineer. Currently, it gets around 1.8 Million clicks per month.My Tribe focus was on internal tools for the employees and customer service agents. Implemented marketplace wide features such as discount codes and anonymous seller-buyer communications. Maintained 4 microservices with a team of 4.",
  },
  {
    company: "Schmiede.ONE GmbH",
    title: "Software Engineer",
    location: "Düsseldorf, Germany",
    startTime: "08.01.2017",
    endTime: "11.01.2019",
    url: "https://schmiede.one/",
    image: "/images/schmiede_thumbnail.jpg",
    description:
      "I have worked mainly as a Full-Stack Developer. Shipped 3 Projects ( 2 Mobile, 1 Web) both individually and with a team. Used ReactJS and React Native on Front-End and Node JS with Express on the backend. Used Docker and AWS for servers.Made a research project for detecting potatoes on videos with Tensorflow. Achieved multiple object detections on low-end android over 30 fps. Participated in the hiring processes.",
  },
  {
    company: "Taleworlds Entertainment",
    title: "Software Engineer Intern",
    location: "Ankara, Turkey",
    startTime: "07.01.2015",
    endTime: "09.01.2015",
    url: "https://www.taleworlds.com/",
    image: "/images/taleworlds_thumbnail.svg",
    description:
      "Worked on the GUI Team for the Mount&Blade: Bannerlord. Implemented ActionScript and C# Connection between scenes. Participated in testing.",
  },
];
