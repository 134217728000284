import AutomaticFaceSwap from "./ProjectData/AutomaticFaceSwap";
import BouncyEggs from "./ProjectData/BouncyEggs";
import ColorTransfer from "./ProjectData/ColorTransfer";
import DiscordSoundboard from "./ProjectData/DiscordSoundboard";
import EasyWords from "./ProjectData/EasyWords";
import EstimatingPreferencesYelp from "./ProjectData/EstimatingPreferencesYelp";
import HashCode2007 from "./ProjectData/HashCode2007";
import Kelimator from "./ProjectData/Kelimator";
import PredictingReviewsAttitude from "./ProjectData/PredictingReviewsAttitude";
import SiteCrawler from "./ProjectData/SiteCrawler";
import SpaceRush from "./ProjectData/SpaceRush";
export const PROJECTS = [
  {
    title: "Automatic FaceSwap",
    url: "automatic-faceswap",
    thumbnail: "images/faceswap_thumbnail.jpg",
    date: "01.01.2017",
    info:
      "This project aims to swap faces on given two images. It automatically detects both faces and scales them using Viola-Jones Algorithm. Written in Matlab",
    tags: ["Matlab", "Image Processing"],
    component: <AutomaticFaceSwap />,
    images: [
      { url: "/images/faceswap_source.jpg", title: "Source" },
      { url: "/images/faceswap_target.jpg", title: "Target" },
      { url: "/images/faceswap_result.jpg", title: "Result" },
    ],
  },
  {
    title: "Bouncy Egg",
    url: "bouncy-egg",
    thumbnail: "images/bouncyeggs_ss2.png",
    date: "10.01.2015",
    info:
      "This is a game for Android phones made with Unity 3D. You have a pan and the eggs keep coming at you! You have to bounce them before breaking them. How long will you survive? It has features like unlockables, leaderboard, news. Available on Google Play.",
    tags: ["Unity 3D", "Android", "Google Play", "C#", "Javascript"],
    component: <BouncyEggs />,
    images: [
      { url: "/images/bouncyeggs_ss1.png" },
      { url: "/images/bouncyeggs_ss2.png" },
      { url: "/images/bouncyeggs_ss3.png" },
      { url: "/images/bouncyeggs_ss4.png" },
    ],
    isCarousel: true,
  },
  {
    title: "Color Transfer Between Images",
    url: "color-transfer-between-images",
    thumbnail: "images/colortransfer_source.jpg",
    date: "12.01.2016",
    info:
      "In this project, I implemented an algorithm using Ruderman method to transfer colors in given source image to target image. ",
    tags: ["Matlab", "Image Processing"],
    component: <ColorTransfer />,
    images: [
      { url: "/images/colortransfer_target.jpg", title: "Target" },
      { url: "/images/colortransfer_source.jpg", title: "Source" },
      { url: "/images/colortransfer_result.jpg", title: "Result" },
    ],
  },
  {
    title: "Predicting Reviews Attitude",
    url: "predicting-review-attitude",
    thumbnail: "images/bog_naive.png",
    date: "12.01.2016",
    info:
      "In this project, I implemented an algorithm using python to predict if the given review is positive or negative. I used “Bag of words” method to get the frequency of the words and Naive Bayes to determine the class.",
    tags: ["Matlab", "Image Processing"],
    component: <PredictingReviewsAttitude />,
  },
  {
    title: "Estimating Preferences By Region Using Yelp Data",
    url: "estimating-preferences-by-region",
    thumbnail: "images/estimatepreferences_thumbnail.jpg",
    date: "01.01.2017",
    info:
      "I did this project with a team for my Machine Learning Project. Our project aims to estimate what customer values on restaurants by region. We intend to improve the business for restaurants that currently open or will we available in future.",
    tags: ["Machine Learning", "Data Analytics", "Python"],
    component: <EstimatingPreferencesYelp />,
    images: [
      { url: "/images/estimatepreferences_results1.jpg" },
      { url: "/images/estimatepreferences_results2.jpg" },
      { url: "/images/estimatepreferences_results3.jpg" },
    ],
  },
  {
    title: "Google Hash Code 2017",
    url: "google-hash-code-2017",
    thumbnail: "images/googlehashcode_thumbnail.jpg",
    date: "02.26.2017",
    info:
      "Hash Code is a team-based programming competition organized by Google Paris. Every year they announce a real-life problem as the challenge. This year my team and I joined and used Java.",
    tags: ["Java", "Competition", "Algorithms"],
    component: <HashCode2007 />,
    images: [],
  },
  {
    title: "Site Crawler",
    url: "site-crawler",
    thumbnail: "images/crawler_thumbnail.jpg",
    date: "02.18.2017",
    info:
      "A simple web crawler that only scans the given domain. It scans all of the links both internal and external and saves them and also stores all the assets for each page. This small coding project made for job inteview.",
    tags: ["Java"],
    component: <SiteCrawler />,
    images: [],
  },
  {
    title: "Space Rush",
    url: "space-rush",
    thumbnail: "images/spacerush_thumbnail.png",
    date: "02.18.2014",
    info:
      "Space Rush is a endless space game. The Goal is having the highest score and lasting the longest. You can join the highscore table if you connect via Facebook. Made with unity and available on Google Play.",
    tags: ["Unity 3D", "Android", "Google Play", "C#", "Javascript"],
    component: <SpaceRush />,
    images: [{ url: "/images/spacerush_ss1.png" }],
    isCarousel: true,
  },
  {
    title: "Kelimatör",
    url: "kelimator",
    thumbnail: "images/kelimator_thumbnail.png",
    date: "08.05.2017",
    info:
      "Kelimatör is a word guessing game. There are themes and levels in these themes for users to discover. Size of the board starts with 2x2 and it increases as the levels go as well as the difficulty.",
    tags: ["Unity 3D", "Android", "Google Play", "C#", "Javascript"],
    component: <Kelimator />,
    images: [
      { url: "/images/kelimator_ss1.webp" },
      { url: "/images/kelimator_ss2.webp" },
      { url: "/images/kelimator_ss3.webp" },
      { url: "/images/kelimator_ss4.webp" },
      { url: "/images/kelimator_ss5.webp" },
    ],
    isCarousel: true,
  },
  {
    title: "Easy Words - Under Development",
    url: "easy-words",
    thumbnail: "images/easywords_thumbnail.png",
    date: "01.01.2021",
    info:
      "Easy Words is a Language App to learn and remember German words. You can create your own word lists or use the pre-made ones. You will get daily reminders and depending on your list type you will automatically get new words every day.",
    tags: ["React Native", "Javascript", "Google Play", "Android"],
    component: <EasyWords />,
    images: [
      { url: "/images/easywords_ss1.png" },
      { url: "/images/easywords_ss2.png" },
      { url: "/images/easywords_ss3.png" },
      { url: "/images/easywords_ss4.png" },
      { url: "/images/easywords_ss5.png" },
      { url: "/images/easywords_ss6.png" },
    ],
    isCarousel: true,
  },
  {
    title: "Discord Soundboard",
    url: "discord-soundboard",
    thumbnail: "images/soundboard_thumbnail.jpg",
    date: "03.01.2020",
    info:
      "Discord Soundboard is an app for Discord. It has over 300 sounds that you can play on your channel on real-time by using our mobile or web client.",
    tags: ["React Native", "Javascript", "Google Play", "ReactJS", "NodeJS"],
    component: <DiscordSoundboard />,
    images: [
      { url: "/images/soundboard_ss1.jpg" },
      { url: "/images/soundboard_ss2.jpg" },
      { url: "/images/soundboard_ss3.jpg" },
      { url: "/images/soundboard_ss4.jpg" },
      { url: "/images/soundboard_ss5.jpg" },
      { url: "/images/soundboard_ss6.jpg" },
    ],
    isCarousel: true,
  },
];
