import CodeIcon from '@material-ui/icons/Code';
import StorefrontIcon from '@material-ui/icons/Storefront';
import StorageIcon from '@material-ui/icons/Storage';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import DnsIcon from '@material-ui/icons/Dns';
import BrushIcon from '@material-ui/icons/Brush';

export const SKILLS = [
  {
    title: "Backend",
    icon: CodeIcon,
    text : 'Nodejs/Express, PHP with Symfony, Spring/JAX-RX. RESTful API Design, JWT, Pub/Sub.',
  },
  {
    title: "Frontend",
    icon: StorefrontIcon,
    text : 'Angular 7+, ReactJS, React Native, Material UI, Bootstrap, Android, Redux, Ngrx.',
  },
  {
    title: "DevOps",
    icon: DnsIcon,
    text : 'AWS EC2/ECS, Google Cloud, Jenkins.',
  },
  {
    title: "Databases",
    icon: StorageIcon,
    text : 'MySQL, MongoDB, WatermelonDB, RealmDB, Dynamo DB, Table planning, query optimizing.',
  },
  {
    title: "Games",
    icon: SportsEsportsIcon,
    text : 'Unity 3D, Google Play, Apple Store, Revenue Planning, Advertising.',
  },
  {
    title: "Design / UX",
    icon: BrushIcon,
    text : 'Photoshop, Blender 3D for my personal projects. UI/UX experience from many shipped mobile apps and websites. '
  },
];
